<template>
	<div>
		<div v-if="step1==1">
			<el-steps :active="1" align-center>
				<el-step title="设置关键词" description="step1"></el-step>
				<el-step title="预览" description="step2"></el-step>
				<el-step title="完成" description="step3"></el-step>
			</el-steps>
			<el-row :gutter="24" style="margin-top: 60px;">
				<el-col :span="6">
					<div style="color: #fff;">
						空格
					</div>
				</el-col>
				<el-col :span="12">
					<div style="display: flex;align-items: center;">
						<div>修改方式：</div>
						<el-radio v-model="radio" label="1">新增</el-radio>
						<el-radio v-model="radio" label="2">替换</el-radio>
						<el-radio v-model="radio" label="3">删除</el-radio>
					</div>
					<div v-if="radio=='1'" style="display: flex;align-items: center;margin-top: 30px;">
						<div>新增位置：</div>
						<el-radio v-model="weizhi" label="1">商品名称前</el-radio>
						<el-radio v-model="weizhi" label="2">商品名称后</el-radio>
					</div>
					<div v-if="radio=='1'">
						<div style="margin-top: 30px;color: #8d8d8d;">
							<div class="u-m-b">关键词</div>
							<div class="u-m-b">将在“商品名称”{{weizhi=='1'?'前':'后'}}增加关键词</div>
							<div>
								<el-input size="medium" placeholder="请输入内容" v-model="keyword">
								</el-input>
							</div>
						</div>
						<div class="u-h-b">
							<el-button type="primary" @click="shezhi" round>开始设置</el-button>
						</div>
					</div>
					<div v-if="radio=='2'">
						<div style="margin-top: 30px;color: #8d8d8d;">
							<div class="u-m-b">查找</div>
							<div class="u-m-b">原商品中包含的关键词</div>
							<div>
								<el-input size="medium" placeholder="请输入内容" v-model="keyword">
								</el-input>
							</div>
						</div>
						<div style="margin-top: 20px;color: #8d8d8d;">
							<div class="u-m-b">替换为</div>
							<div class="u-m-b">新商品将原关键词替换为新关键词</div>
							<div>
								<el-input size="medium" placeholder="请输入内容" v-model="keyword1">
								</el-input>
							</div>
						</div>
						<div class="u-h-b">
							<el-button type="primary" @click="tihuan" round>开始替换</el-button>
						</div>
					</div>

					<div v-if="radio=='3'">
						<div style="margin-top: 30px;color: #8d8d8d;">
							<div class="u-m-b">查找</div>
							<div class="u-m-b">将删除原商品中包含的关键词</div>
							<div>
								<el-input size="medium" placeholder="请输入内容" v-model="keyword1">
								</el-input>
							</div>
						</div>
						<div class="u-h-b">
							<el-button type="primary" round>开始删除</el-button>
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div style="color: #fff;">
						空格
					</div>
				</el-col>
			</el-row>
		</div>
		<div v-if="step1==2" class="body">
			<el-row :gutter="20">
				<el-col :span="20" style="height: 22px;">
					<div class="grid-content bg-purple" style="min-height: 20px;">
						<div class="steps-tip first-tip" style="height:20px;"><em>1</em>设定价格</div>
					</div>
				</el-col>
				<el-col :span="4">
				</el-col>
			</el-row>
			<el-row :gutter="24" style="height: 40px;">
				<el-col :span="5">
					<div style="display: flex;align-items: center;margin-left: 20px;height: 32px;">
						<div style="margin-right: 10px;">关键词：{{keyword}}</div>
					</div>
				</el-col>
				<el-col :span="15">
					<div style="display: flex;align-items: center;margin-left: 20px;">
						<div style="margin-right: 10px;">替换关键词：{{keyword1}}</div>
					</div>
				</el-col>
			</el-row>
			<el-row :gutter="20">
				<el-col :span="20">
					<div class="grid-content bg-purple">
						<div class="steps-tip first-tip"><em>2</em>选择商品</div>
					</div>
				</el-col>
				<el-col :span="4">
					<div class="grid-content bg-purple">
						<el-input placeholder="请输入内容" prefix-icon="el-icon-search" v-model="keywords">
						</el-input>
					</div>
				</el-col>
			</el-row>
			<el-container style="height: calc(100vh - 290px); border: 1px solid #eee;width: 100%;">
				<el-aside width="200px" style="background-color: rgb(238, 241, 246)">
					<el-menu :default-openeds="['1', '3']" mode="vertical">
						<el-menu-item @click="actmenu(0)" index="0">
							全部分类
						</el-menu-item>
						<el-menu-item @click="actmenu(item.id)" v-for="(item, index) in catelist" :key="item.id"
							:index="index">
							<div style="display: flex;align-items: center;justify-content: center;">
								<div style="flex: 1;">{{item['name']}}</div><em class="u-em">1</em>
							</div>
						</el-menu-item>
					</el-menu>
				</el-aside>
				<div style="width: 100%;">
					<el-card style="">
						<el-table tooltip-effect="dark" height="calc(100vh - 360px)" border :data="pagedTableData"
							@selection-change="handleSelectionChange">
							<el-table-column type="selection" width="55">
							</el-table-column>
							<el-table-column prop="name" label="商品"><template slot-scope="scope">
									<div v-html='scope.row.name'></div>
								</template></el-table-column>
							<el-table-column prop="guige" label="规格"> </el-table-column>
							<el-table-column prop="isp" label="简单商品"> </el-table-column>
							<el-table-column prop="jiage" label="价格"> </el-table-column>
							<el-table-column prop="hdprice" label="活动价"> </el-table-column>
							<el-table-column prop="kucun" label="库存"> </el-table-column>
							<el-table-column prop="istb" label="同步店铺"> </el-table-column>
							<!-- 其他列 -->
						</el-table>
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:page-sizes="[10, 20, 30, 40, 50]" :current-page.sync="pageNo"
							layout="total, sizes, prev, pager, next, jumper" :total="tableData.length">
						</el-pagination>
					</el-card>
				</div>
			</el-container>
			<el-row v-if="step==1">
				<div style="text-align: right;margin-top: 10px;padding-right: 50px;">
					<el-button @click="xiayibu" type="primary" round>下一步</el-button>
				</div>
			</el-row>
			<div style="margin-top: 20px;" v-if="step==2">
				<el-row :gutter="20">
					<el-col :span="20">
						<div class="grid-content bg-purple">
							<div class="steps-tip first-tip"><em>2</em>选择店铺</div>
						</div>
					</el-col>
				</el-row>
				<div>
					<el-row :gutter="20">
						<el-col :span="4">
							<el-select v-model="value" placeholder="请选择">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-col>
						<el-col :span="6">
							<el-input placeholder="搜索店铺名称/ID（多个以,分隔）" v-model="input3" class="input-with-select">
								<el-button slot="append" icon="el-icon-search"></el-button>
							</el-input>
						</el-col>
					</el-row>
					<el-table ref="multipleTable" :data="tableData1" tooltip-effect="dark" style="width: 100%"
						@selection-change="handleSelectionChange">
						<el-table-column type="selection" width="55">
						</el-table-column>
						<el-table-column label="日期" width="120">
							<template slot-scope="scope">{{ scope.row.date }}</template>
						</el-table-column>
						<el-table-column prop="name" label="姓名" width="120">
						</el-table-column>
						<el-table-column prop="address" label="地址" show-overflow-tooltip>
						</el-table-column>
					</el-table>
				</div>
				<el-row :gutter="24">
					<el-col :span="19" style="height: 50px;display: flex;align-items: center;">
						<div style="margin-left: 50px;"></div>
					</el-col>
					<el-col :span="5" style="height: 50px;display: flex;align-items: center;">
						<div style="text-align: right;margin-top: 10px;">
							<el-button @click="quxiao" round>取消</el-button>
							<el-button @click="tijiao" type="primary" round>确认提交</el-button>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	export default {
		data() {
			return {
				radio: '2',
				weizhi: '1',
				keyword: '',
				keyword1: '',
				keywords: '',
				fangshi: '1',
				pageNo: 1,
				pageSize: 10,
				tableData: [],
				catelist: [{}],
				goodslist: [{}],
				categoryId: 0,
				input2: "",
				form: {},
				multipleSelection: [],
				step1: 1,
				step: 1,
				tableData1: [{
					date: '2016-05-03',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}, {
					date: '2016-05-02',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}, {
					date: '2016-05-04',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}],
				options: [{
					value: '选项1',
					label: '全部渠道'
				}, {
					value: '选项2',
					label: '饿了么外卖'
				}, {
					value: '选项3',
					label: '饿了么零售'
				}],
				value: '选项1',
				select: ''
			}
		},
		created() {
			console.log()
		},
		computed: {
			pagedTableData() {
				return this.tableData.filter(
					(v, i) =>
					i >= (this.pageNo - 1) * this.pageSize &&
					i <= this.pageNo * this.pageSize - 1
				);
			}
		},
		methods: {
			shezhi() {
				if (this.keyword.trim() == "") {
					this.$message({
						showClose: true,
						message: '请填写关键词',
						type: 'warning'
					});
					return;
				}
				this.step1 = 2;
				this.getcatelist();
				this.getgoodslist();
			},
			tihuan() {
				if (this.keyword.trim() == "") {
					this.$message({
						showClose: true,
						message: '请填写关键词',
						type: 'warning'
					});
					return;
				}
				if (this.keyword1.trim() == "") {
					this.$message({
						showClose: true,
						message: '请填写替换词',
						type: 'warning'
					});
					return;
				}
				this.step1 = 2;
				this.getcatelist();
				this.getgoodslist();
			},
			quxiao() {
				//this.step = 1;
				this.$router.go(0)
			},
			tijiao() {
				console.log("ss");
			},
			xiayibu() {
				this.step = 2;
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
				console.log("multipleSelection", this.multipleSelection);
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.pageNo = val;
			},
			actmenu(id) {
				this.categoryId = id;
				this.getgoodslist();
				console.log(id)
			},
			handleEdit(index, row) {
				console.log("sdss", row);
				this.goToAboutPage();
				// 编辑操作
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.pageNo = 1;
			},
			goToAboutPage() {
				this.$router.push({
					path: '/form/basicForm?param=ssasasa',
					query: {
						title: '你好'
					}
				});
			},
			getgoodslist() {
				const loading = this.$loading({
					lock: false,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				const formData1 = new FormData()
				formData1.append('act', "list")
				formData1.append('categoryId', this.categoryId.toString())
				formData1.append('token', "1f9e6cb4759d7e93d38280388a5dd120")
				const url = 'http://www.vssite.com/api/Goods.aspx';
				let keyword = this.keyword;
				let keyword1 = this.keyword1;
				let radio = this.radio;
				let weizhi = this.weizhi;
				axios.post(url, formData1).then(data => {
					setTimeout(function() {
						loading.close();
					}, 200)
					if (data.data.ret != 0) {
						console.log("sss")
					} else {
						var lists = data.data.data;
						lists.forEach(function(item, index) {
							if (radio == "2") {
								let rule = eval("/" + keyword + "/g");
								item.name = item.name.replace(rule,
									"<span style='background-color: yellow'>" +
									keyword1 +
									"</span>");
							} else {
								item.name = weizhi == "1" ? "<span style='background-color: yellow'>" +
									keyword +
									"</span>" + item.name : item.name +
									"<span style='background-color: yellow'>" + keyword + "</span>";
							}
						})
						console.log("keyword1", keyword1);
						this.tableData = lists;
					}
					console.log('data', data)
				}).catch(response => {
					console.log(response)
				})
			},
			getcatelist() {
				const formData1 = new FormData()
				formData1.append('act', "list")
				formData1.append('token', "1f9e6cb4759d7e93d38280388a5dd120")
				const url = 'http://www.vssite.com/api/addGoodsCategory.aspx'
				axios.post(url, formData1).then(data => {
					if (data.data.ret != 0) {
						console.log("sss")
					} else {
						this.catelist = data.data.data;
					}
					console.log('data', data)
				}).catch(response => {
					console.log(response)
				})
			}
		}

	}
</script>

<style>
	.el-main {
		position: relative;
	}

	.body {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		overflow: hidden;
		padding: 20px;
	}

	.first-tip {
		width: 50%;
		float: left;
		height: 39px;
		line-height: 39px;
	}

	.steps-tip {
		font-weight: 500;
		text-align: left;
		font-size: 16px;
		position: relative;
		padding-left: 30px;
		line-height: 22px;
		display: block;
		margin-bottom: 10px;
	}

	.steps-tip em {
		display: block;
		width: 20px;
		height: 20px;
		border-radius: 100%;
		background: #F5F5F5;
		text-align: center;
		line-height: 20px;
		font-size: 14px;
	}

	.steps-tip em {
		background-color: #2DAED6;
		color: #FFF;
		position: absolute;
		left: 0;
		top: 0;
	}

	.u-h-b {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 20px;
	}

	.u-m-b {
		margin-bottom: 10px;
	}
</style>
